import {
    Box,
    Container,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { FindUsOnFaceBookButton } from './../../components';

const useStyles = createUseStyles({
    footer: {
        paddingTop: 70,
        paddingBottom: 70,
        backgroundColor: '#f6f6f6'
    },
    logo: {
        height: 30,
        width: 'auto',
        filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))'
    }
});

export default function AppFooter(props) {
    const {
        footer,
        logo,
    } = useStyles();

    return (
        <div className={footer}>
            <Container maxWidth="lg">
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        sm={7}
                        item
                    >
                        <img
                            alt="nogul"
                            src="/static/images/logo.png"
                            className={logo}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        sm={5}
                        item
                    >
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Our office
                        </Typography>
                        <Typography variant="body1">
                            Park Place, 4th floor, #404, Sukhbaatar district, 1st khoroo, Ulaanbaatar, Mongolia
                        </Typography>
                        <Box mt={4}>
                            <FindUsOnFaceBookButton
                                href={process.env.REACT_APP_FACEBOOK_URL}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box my={3}>
                    <Divider />
                </Box>
                <Typography
                    align="center"
                    variant="body2"
                    color="GrayText"
                >
                    Copyright &copy; {new Date().getFullYear()} Meta Keys. All rights reserved.
                </Typography>
            </Container>
        </div>
    );
}