import { default as clsx } from 'clsx';
import { default as NumberFormat } from 'react-number-format';
import { createUseStyles } from 'react-jss';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import { Check } from '@mui/icons-material';

const useStyles = createUseStyles({
    plan: {
        padding: 24,
        borderRadius: 16,
        transition: 'all 0.3s',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#fff'
    },
    planHover: {
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)'
        },
    },
    listItem: {
        alignItems: 'flex-start !important'
    },
    listItemIcon: {
        marginTop: 6
    }
});

export default function PricingPlan(props) {
    const {
        plan,
        planHover,
        listItem,
        listItemIcon
    } = useStyles();
    const {
        name,
        price,
        durationType,
        features
    } = props;

    return (
        <div className={clsx(plan, planHover)}>
            <Typography
                variant="h6"
                sx={{
                    textAlign: 'center'
                }}
            >
                {name}
            </Typography>
            <Stack
                alignItems="center"
                justifyContent="center"
                direction="row"
                spacing={1}
                sx={{
                    mt: 2
                }}
            >
                <Typography variant="h4">
                    <NumberFormat
                        displayType="text"
                        prefix="¥"
                        value={price}
                        thousandSeparator
                    />
                </Typography>
                <Typography variant="body1">
                    / {durationType}
                </Typography>
            </Stack>
            <Box my={4}>
                <Divider />
            </Box>
            <List disablePadding>
                {features.map((feature, index) => (
                    <ListItem
                        className={listItem}
                        key={index}
                        disableGutters
                    >
                        <ListItemIcon className={listItemIcon}>
                            <Check color="success" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}