import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { App } from './layouts';
import { theme } from './theme/muiTheme';

export default () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider >
)