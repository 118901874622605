import { default as Slider } from 'react-slick';
import { default as clsx } from 'clsx';
import { scroller } from 'react-scroll';
import { createUseStyles } from 'react-jss';
import {
    Box,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import {
    Comment,
    DownloadButton,
    Feature,
    PricingPlan
} from './../components';
import { Check } from '@mui/icons-material';

const useStyles = createUseStyles({
    section: {
        paddingTop: 100,
        paddingBottom: 100
    },
    gray: {
        backgroundColor: '#f6f6f6'
    },
    imageContainer: {
        paddingTop: 100,
        paddingBottom: 100,
        textAlign: 'center'
    },
    image: {
        width: '100%',
        height: 'auto',
        display: 'inline-block'
    },
    listItem: {
        alignItems: 'flex-start !important'
    },
    listItemIcon: {
        marginTop: 6
    }
});

export default function Home() {
    const {
        section,
        gray,
        imageContainer,
        image,
        listItem,
        listItemIcon
    } = useStyles();

    const scrollTo = target => {
        scroller.scrollTo(target, {
            smooth: 'easeInOutQuart',
            duration: 1600
        });
    }

    return (
        <div>
            <div className={clsx(section, gray)}>
                <Container maxWidth="lg">
                    <Grid
                        alignItems="center"
                        spacing={2}
                        container
                    >
                        <Grid
                            xs={12}
                            md={6}
                            item
                        >
                            <Typography
                                component="h1"
                                variant="h3"
                                gutterBottom
                            >
                                The Mongolian video contents for children
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '1.25rem'
                                }}
                            >
                                Shows your family wants, hand-picked by caring people. Safe & free.
                            </Typography>
                            <Button
                                onClick={() => scrollTo('download-section')}
                                variant="contained"
                                size="large"
                                disableElevation
                                sx={{
                                    mt: 4
                                }}
                            >
                                Watch free now
                            </Button>
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            item
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'none',
                                        md: 'block'
                                    }
                                }}
                            >
                                <div className={imageContainer}>
                                    <img
                                        className={image}
                                        src="/static/images/mockup.png"
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        component="h2"
                        variant="h4"
                        gutterBottom
                    >
                        What we do
                    </Typography>
                    <Grid
                        justifyContent="center"
                        container
                        sx={{
                            mt: 6
                        }}
                    >
                        <Grid
                            xs={12}
                            md={9}
                            item
                        >
                            <Typography
                                align="center"
                                variant="body1"
                            >
                                Nogul is a video streaming app that entertains and educates children of all age groups with <b>Mongolian</b> popular shows, movies, music, stories, cartoons, activity guides, and more. All our content is for 4-12 years old boys and girls. Download the app and make your children happy!
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        component="h2"
                        variant="h4"
                        gutterBottom
                    >
                        A safe place for your child
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                    >
                        Nogul is a safe streaming platform with content hand-picked by parents like you.<br />Join our family and find peace-of-mind knowing your children are safe.
                    </Typography>
                    <Grid
                        spacing={2}
                        container
                        sx={{
                            mt: 6
                        }}
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            item
                            container
                        >
                            <Feature
                                iconSrc="/static/images/safety.png"
                                title="Curated content"
                                description="Every show available on our service has been watched and screened by a <b>real human being</b>."
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            container
                            item
                        >
                            <Feature
                                iconSrc="/static/images/parental-controls.png"
                                title="Parental controls"
                                description="Be the best parent, even when you’re not there. <b>Screen time limit</b> and more features are available to all users."
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            container
                            item
                        >
                            <Feature
                                iconSrc="/static/images/easy-watch.png"
                                title="Easy to watch"
                                description="Whether you have a tablet, smart tv, or mobile phone, <b>Nogul is there for you</b>."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Grid
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={4}
                        container
                        sx={{
                            mt: 6
                        }}
                    >
                        <Grid
                            xs={12}
                            md={7}
                            lg={6}
                            item
                        >
                            <Typography
                                align="center"
                                component="h2"
                                variant="h4"
                            >
                                Watch video content wherever you want for your child
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={5}
                            item
                        >
                            <List disablePadding>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Enjoy a safe watching experience for children" />
                                </ListItem>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="All contents verified with the Copyright" />
                                </ListItem>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Family-friendly entertainment shows" />
                                </ListItem>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Download video content without the internet" />
                                </ListItem>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="Unlimited screening" />
                                </ListItem>
                                <ListItem
                                    className={listItem}
                                    disableGutters
                                >
                                    <ListItemIcon className={listItemIcon}>
                                        <Check color="success" />
                                    </ListItemIcon>
                                    <ListItemText primary="We promise the options are endless" />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={clsx(section, gray)}>
                <Slider
                    arrows={false}
                    speed={1000}
                    autoplay
                    dots
                    swipeToSlide
                >
                    <div>
                        <Container maxWidth="lg">
                            <Grid
                                justifyContent="center"
                                container
                            >
                                <Grid
                                    sm={7}
                                    item
                                >
                                    <Comment
                                        name="Baatar Sharnuud"
                                        body="I love this app. Literally, my child loves it, its free and convienient. It is also useful and very safe, just like its description. Hit that 5 star! Keep up!"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <div>
                        <Container maxWidth="lg">
                            <Grid
                                justifyContent="center"
                                container
                            >
                                <Grid
                                    sm={7}
                                    item
                                >
                                    <Comment
                                        name="Baatar Sharnuud"
                                        body="I love this app. Literally, my child loves it, its free and convienient. It is also useful and very safe, just like its description. Hit that 5 star! Keep up!"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Slider>
            </div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        component="h2"
                        variant="h4"
                    >
                        Our plans for your choice
                    </Typography>
                    <Grid
                        spacing={4}
                        container
                        sx={{
                            mt: 6
                        }}
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            item
                        >
                            <PricingPlan
                                name="Basic"
                                price="80"
                                durationType="month"
                                features={[
                                    'Premium shows & movies',
                                    'Passcode protection',
                                    'Parental Controls (includes Screen Time Limits)'
                                ]}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            item
                        >
                            <PricingPlan
                                name="Standard"
                                price="200"
                                durationType="3 month"
                                features={[
                                    'Premium shows & movies',
                                    'Passcode protection',
                                    'Parental Controls (includes Screen Time Limits)'
                                ]}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            item
                        >
                            <PricingPlan
                                name="Premium"
                                price="380"
                                durationType="6 month"
                                features={[
                                    'Premium shows & movies',
                                    'Passcode protection',
                                    'Parental Controls (includes Screen Time Limits)'
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Typography
                        color="GrayText"
                        variant="body1"
                        fontStyle="italic"
                        sx={{
                            mt: 6,
                            textAlign: 'center'
                        }}
                    >
                        *You can buy plan on nogul app.
                    </Typography>
                </Container>
            </div>
            <div id="download-section">
                <div className={section}>
                    <Container maxWidth="lg">
                        <Grid
                            alignItems="center"
                            spacing={4}
                            container
                        >
                            <Grid
                                xs={12}
                                md={5}
                                item
                            >
                                <Typography
                                    component="h2"
                                    variant="h4"
                                    gutterBottom
                                >
                                    Start watching now!
                                </Typography>
                                <Typography variant="body1">
                                    Get Nogul app on your device of choice. Download the app and start watching for free today.
                                </Typography>
                                <Grid
                                    spacing={2}
                                    container
                                    sx={{
                                        mt: 4
                                    }}
                                >
                                    <Grid item>
                                        <DownloadButton
                                            href={process.env.REACT_APP_APP_STORE_URL}
                                            type="app-store"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DownloadButton
                                            href={process.env.REACT_APP_GOOGLE_PLAY_URL}
                                            type="google-play"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                md={7}
                                item
                            >
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            md: 'block'
                                        }
                                    }}
                                >
                                    <img
                                        src="/static/images/device.png"
                                        width="100%"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    )
}