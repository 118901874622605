import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    spacer: {
        flexGrow: 1
    }
});

export default function Spacer() {
    return (
        <div className={useStyles().spacer}></div>
    )
}