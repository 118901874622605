import { createUseStyles } from 'react-jss';
import {
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { Remove } from '@mui/icons-material';

const useStyles = createUseStyles({
    section: {
        paddingTop: 100,
        paddingBottom: 100
    },
    listItem: {
        alignItems: 'flex-start !important'
    },
    listItemIcon: {
        marginTop: 8
    }
});

export default function PrivacyPolicy() {
    const {
        section,
        listItem,
        listItemIcon
    } = useStyles();

    return (
        <div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        component="h2"
                        variant="h4"
                        sx={{
                            mb: 4
                        }}
                    >
                        Privacy policy
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        This Privacy Policy is a document that regulates the registration, use, transmission and storage of personal information of Nomadic Bear Games LLC (hereinafter referred to as the Company).
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        The Company maintains the privacy of all registered users of the Nogul Mobile Application (collectively referred to as the "Service") in accordance with this document.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        The Company shall not disclose any customer information to third parties, except as provided in this Privacy Policy and in any other manner provided by Mongolian law. If you receive the service, you agree to the user's privacy policy.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        1. About the product
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        Through the Nogul mobile application, you can see the following types of children's art for children aged 4-12.
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Music" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Fairy tale" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Poems" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Folklore" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Riddles and riddles" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Cartoons (animation)" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="TV shows ect." />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        2. Information to be obtained during user registration
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        This mobile application is available for 16 and above. Parents and guardians are fully responsible if a younger customer makes a fake order. The Law on Protection of Personal Privacy and other relevant legislation shall apply to the creation, processing and use of personal information.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        The user account is created as follows:
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Email address" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Password" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        3. Use of information
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        User information can be used as follows. These include:
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Dispute resolution, fees, and technical issues" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="To prevent illegal activities and to comply with the Terms of Service" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="When sending marketing and promotional offers via text and email on behalf of the company" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Linking or merging customer personal information with information obtained from others in order to receive customer needs and feedback and provide better service" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        4. Information processing and transmission
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Transfer for the purpose of enforcing the decision of the state authority and participating in the investigation in accordance with the law" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The Company is obliged to disclose and share customer information in order to fulfill its legal obligations" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The company may be transferred to a third party that supports customer identification, anti-money laundering and anti-terrorist financing activities" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        5. Security
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="We comply with Mongolian law to protect the privacy and security of users' personal information" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="In order to ensure the security of customer information, legal, organizational and technical measures shall be taken to protect the user's information from unauthorized or unauthorized access, destruction, alteration, copying, copying or transfer to third parties" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Relevant personnel who access the user's personal information are obliged not to disclose the personal information" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        6. Access to information
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        Users have the right to obtain their personal information from us. In addition, only users have the right to view and access their information if they receive services from a third party working with the company.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        7. Complete the use and processing of personal information
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        We will stop processing your personal information under the following conditions.
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The user has reported that Nomadic Bair Games has opted out of the website and application" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Processing and use of information is prohibited by the decision of the state authority" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Unavailability of information due to unforeseen or force majeure circumstances" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Nomadic Bair Games web and application service provider ceased operations" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        8. Contact
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        If you have any questions regarding personal information or security, please contact us at hello@nogul.mn or call (+976) 88102222.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        9. Liability
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The owner of the Nogul mobile application shall be liable in accordance with the Criminal or Violations Law of Mongolia if he / she illegally discloses, processes, transmits or uses the User's personal information" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The user is responsible for non-compliance with the obligation to enter personal information" />
                        </ListItem>
                    </List>
                </Container>
            </div>
        </div>
    )
}
