import { default as clsx } from 'clsx';
import { createUseStyles } from 'react-jss';
import { Typography } from '@mui/material';

const useStyles = createUseStyles({
    feature: {
        padding: 32,
        cursor: 'default',
        textAlign: 'center',
        borderRadius: 16,
        backgroundColor: '#fff',
        transition: 'all 0.3s'
    },
    featureIcon: {
        display: 'inline-block',
        height: 130,
        marginBottom: 24
    },
    featureHover: {
        '&:hover': {
            backgroundColor: '#f6f6f6'
        }
    }
});

export default function Feature(props) {
    const {
        feature,
        featureIcon,
        featureHover
    } = useStyles();
    const {
        iconSrc,
        title,
        description
    } = props;

    return (
        <div className={clsx(feature, featureHover)}>
            <img
                className={featureIcon}
                src={iconSrc}
            />
            <Typography
                component="h3"
                variant="h5"
                gutterBottom
            >
                {title}
            </Typography>
            <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                    __html: description
                }}
            />
        </div>
    )
}