import { createUseStyles } from 'react-jss';
import { Typography } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';

const useStyles = createUseStyles({
    comment: {
        textAlign: 'center'
    }
});

export default function Comment(props) {
    const { comment } = useStyles();
    const {
        name,
        body
    } = props;

    return (
        <div className={comment}>
            <FormatQuote
                color="disabled"
                sx={{
                    fontSize: 60,
                    mb: 4
                }}
            />
            <Typography
                variant="h5"
                sx={{
                    mb: 3
                }}
            >
                {body}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textTransform: 'uppercase'
                }}
            >
                {name}
            </Typography>
        </div>
    )
}