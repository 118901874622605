import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#003459'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        subtitle2: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        body1: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        },
        body2: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        },
        button: {
            fontFamily: 'Nunito',
            fontWeight: 800,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        },
        overline: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        }
    }
})