import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    button: {
        display: 'inline-block',
        padding: 12,
        borderRadius: 4,
        backgroundColor: '#000'
    },
    buttonImage: {
        display: 'block',
        width: 100,
        height: 'auto'
    }
});

export default function FindUsOnFaceBookButton({ href }) {
    const {
        button,
        buttonImage
    } = useStyles();

    return (
        <a
            target="_blank"
            href={href}
            className={button}
        >
            <img
                alt="find us on facebook"
                src="/static/images/find-us-on-facebook.png"
                className={buttonImage}
            />
        </a>
    )
}