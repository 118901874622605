import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import {
    AppBar,
    AppFooter
} from './../components/app';
import {
    Home,
    NotFound,
    PrivacyPolicy,
    TermsAndConditions
} from './../pages';

export default function App(props) {
    return (
        <div>
            <BrowserRouter>
                <AppBar />
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/terms-and-conditions">
                        <TermsAndConditions />
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
                <AppFooter />
            </BrowserRouter>
        </div>
    )
}