import { useState } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
    Button,
    Container,
    Drawer,
    IconButton,
    ListItemText,
    MenuList,
    MenuItem,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Spacer } from './../common';

const useStyles = createUseStyles({
    appBar: {
        display: 'flex',
        alignItems: 'center',
        height: 70,
        backgroundColor: '#f6f6f6'
    },
    logo: {
        display: 'inline-block',
        float: 'left',
        margin: '3px 0',
        height: 30,
        width: 'auto',
        filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))'
    },
    paper: {
        minWidth: 280
    }
});

export default function AppBar() {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        appBar,
        logo,
        paper
    } = useStyles();

    const [
        open,
        setOpen
    ] = useState(false);

    return (
        <div className={appBar}>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex'
                }}
            >
                <Link to="/">
                    <img
                        alt="toblo logo"
                        src="/static/images/logo.png"
                        className={logo}
                    />
                </Link>
                <Spacer />
                {isMobile
                    ?
                    <IconButton onClick={() => setOpen(true)}>
                        <Menu />
                    </IconButton>
                    :
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Link
                            to="/"
                            color="inherit"
                            component={Button}
                        >
                            Home
                        </Link>
                        <Link
                            to="/terms-and-conditions"
                            color="inherit"
                            component={Button}
                        >
                            Terms and Conditions
                        </Link>
                        <Link
                            to="/privacy-policy"
                            color="inherit"
                            component={Button}
                        >
                            Privacy policy
                        </Link>
                    </Stack>
                }
            </Container>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper
                }}
            >
                <MenuList>
                    <MenuItem
                        to="/"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <ListItemText primary="Home" />
                    </MenuItem>
                    <MenuItem
                        to="/terms-and-conditions"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <ListItemText primary="Terms and Conditions" />
                    </MenuItem>
                    <MenuItem
                        to="/privacy-policy"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <ListItemText primary="Privacy policy" />
                    </MenuItem>
                </MenuList>
            </Drawer>
        </div>
    )
}