import {
    useEffect,
    useState
} from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    image: {
        height: 50,
        width: 'auto'
    }
});

export default function DownloadButton(props) {
    const { image } = useStyles();
    const {
        href,
        type
    } = props;

    const [
        src,
        setSrc
    ] = useState('');

    useEffect(() => {
        switch (type) {
            case 'app-store':
                setSrc('/static/images/app-store.svg');
                break;
            case 'google-play':
                setSrc('/static/images/google-play.svg');
                break;
        }
    }, []);

    return (
        <a
            target="_blank"
            href={href}
        >
            <img
                src={src}
                className={image}
            />
        </a>
    )
}