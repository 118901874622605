import { createUseStyles } from 'react-jss';
import {
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { Remove } from '@mui/icons-material';

const useStyles = createUseStyles({
    section: {
        paddingTop: 100,
        paddingBottom: 100
    },
    listItem: {
        alignItems: 'flex-start !important'
    },
    listItemIcon: {
        marginTop: 8
    }
});

export default function TermsAndConditions() {
    const {
        section,
        listItem,
        listItemIcon
    } = useStyles();

    return (
        <div>
            <div className={section}>
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        component="h2"
                        variant="h4"
                        sx={{
                            mb: 4
                        }}
                    >
                        Terms and Conditions
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        Thank you for choosing our Nogul mobile application (hereinafter referred to as the system). Through this system, songs, poems, fairy tales and short stories will be delivered to children aged 4-12, with the aim of providing children with cognitive knowledge and helping them to become good people.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        You have the option to purchase our system for 1-6 months, during which time you will have unlimited access to children's content produced by Nomadic Bear Games LLC. Your use of our application indicates that you accept the services of Nomadic Bear Games LLC.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        1. General provisions
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The system is an official technology product of Nomadic Bair Games LLC, and these terms and conditions govern the relationship that arises when viewing the content of Nomadic Bair Games LLC online" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Nomadic Bair Games LLC (hereinafter referred to as the Company) and consumers shall jointly monitor the implementation of this regulation" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="This condition is subject to acceptance and confirmation by the consumer of Nomadic Bair Games LLC 1-6 months before purchasing the right to view children's cognitive content" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        2. Online system and its operations
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The system characters and all content are the property of the company and it is prohibited to copy, duplicate, imitate, resell or otherwise illegally use this online entertainment logo, name, content or other design. All information downloaded, copied and used from the Nogul application will be handled in accordance with Mongolian Intellectual Property Law" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The company is fully responsible for the maintenance of the system, its security and the confidentiality of user information" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="All system operations are automated, with content quality requirements, regular updates, and 24-hour operation. The company is responsible for the normalization of the online website in the event of any system errors, malfunctions, power outages, or server congestion, and it is the responsibility of the users to be notified in the event of a normal operation" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Any order placed during a system failure will be reviewed by the operator, and if any errors are found, the customer will be notified by phone or e-mail of the cancellation of the order and the payment made in connection with the order will be refunded" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The company is fully responsible for updating all types of information on the website and applications" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="When using the system, the user will be presented with a number of highlights to help you make your choice and provide information" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        3. Online system member and its rights
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="To register as a member, enter your e-mail address and password, and if you register as a member, this procedure is considered approved" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The user is solely responsible for the accuracy of the member information and the company is solely responsible for the confidentiality of this information" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Customers 14 years of age and older have the right to order through the system. Parents and guardians are fully responsible if a younger customer makes a false order" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Upon becoming a member of the system, the user will be granted rights. The user's rights include personal information and content viewing history" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The user has the right to report any complaints or innovations related to the operation of the system" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        4. Payment
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The system charges 80 yuan (CHY) for 1 month, 200 yuan (CHY) for 3 months and 380 yuan (CHY) for 6 months" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="When the user gets the right to the package, he will be able to log in and watch all the content on the application for free" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="You can make a payment through an account and pay using the WeChat payment tool" />
                        </ListItem>
                    </List>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        5. Terms of replacement and return terms of service
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        The right arises after the payment of the system, and the created right has the right to return or exchange the service within 3 days, after which it cannot be returned.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4
                        }}
                    >
                        6. Responsibility
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            my: 2
                        }}
                    >
                        The company is not responsible for any transfer of money to the wrong account or incorrect order.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 4,
                            mb: 2
                        }}
                    >
                        7. Other provisions
                    </Typography>
                    <List disablePadding>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Customer feedback, questions and inquiries can be received using the email address hello@nogul.mn and the Nomadic Bair Games Facebook page and chat, or by calling (+976) 88102222" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Complaints and disputes from users shall be resolved in accordance with the terms of the application, the guarantee of confidentiality and the laws of Mongolia" />
                        </ListItem>
                        <ListItem
                            className={listItem}
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItemIcon className={listItemIcon}>
                                <Remove fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="The Company reserves the right to make changes to the terms and conditions of the product, which shall take effect immediately. It is your responsibility to familiarize yourself with the new situation and you do not have the right to change or destroy the company's system or all other conditions, in whole or in part" />
                        </ListItem>
                    </List>
                </Container>
            </div>
        </div>
    )
}
